//=================================================
// Widget
//=================================================

.widget-area {
    @media #{$media_lg} {
        margin-top: $space_lg;
    }
}

.widget-area, [class*='widget-container'] {
    @extend .style-default;

    .widget {
        position: relative;
        border: 1px solid $widget_border_color;
        background: $widget_background_color;
        padding: 2.19rem 1.81rem;
        margin-bottom: $space_lg;
        
        // Widget last child
        &:last-child {
            margin-bottom: 0;
        }

        // Widget title
        .widget-title {
            color: $widget_headline_color;

            a {
                color: $widget_headline_color;
            }
        }

        // Widget search
        &.widget_search {
            padding: 0;
            border: 0;
        }
        
        img, svg {
            max-width: 100%;
            height: auto;
        }
        
        // Gallery
        &.widget_media_gallery {
            .gallery {
                margin-left: -0.5rem;
                margin-right: -0.5rem;
            
                .gallery-item {
                    display: inline-block;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                }
            } 
        }

        // Tag
        &.widget_tag_cloud {
            .tagcloud {
                display: table;
                line-height: 1.75;
                @include lhCrop-b(1.75, 0.75);
                @include lhCrop-a(1.75, 0.75);
            
                .tag-cloud-link {
                    display: inline-block;
                    letter-spacing: 3px;
                    line-height: 1.23;
                    @include lhCrop-b(1.23, 0.75);
                    @include lhCrop-a(1.23, 0.75);
                    word-break: break-all;
                    margin-right: 1.19rem;
                }
            }        
        }
        
        // Calendar
        &.widget_calendar {
            .calendar_wrap {
                text-align: center;
                
                caption {
                    margin-top: 1.39rem;
                }
                
                a {
                    font-weight: bold;
                }
                
                table {
                    width:100%;
                    table-layout: fixed;
                    background-color: $white;
                    
                    td {
                        padding: 0.8rem 0;
                    }
                }
            }
        }
    }
}

// Elementor
[class*='widget-card'] {
    height: 100%;
    
    [class*='widget-container'] {
        height: 100%;
    }
}

.elementor-widget {
    position: static !important;
}
