.track {
    flex-wrap: wrap;

    button {
        position: absolute;
        background-color: #9A7F3E;
        color: #fff;
        padding: 16px 20px;
        font-size: 20px;
        border: none !important;
        right: 0px;
        left: 541px;
        top: 0px;
        width: 200px;

        @media(max-width: 1200px){    
            left: 325px;
        } 

        @media(max-width: 580px){   
          left: 150px;
          font-size: 12px;
          padding: 20px 5px 16px;
          width: 150px;
        } 
    }
 

    label 				 {
        color:#999; 
        font-size:18px;
        font-weight:normal;
        position:absolute;
        pointer-events:none;
        left:95px;
        top:18px;
        transition:0.2s ease all; 
        -moz-transition:0.2s ease all; 
        -webkit-transition:0.2s ease all;

        @media(max-width: 580px){   
          font-size:12px;
          left:20px;
          top:18px;
        } 
      }

    input { 
        background: #fff; 
        font-size: 16px;
        padding: 23px 10px 6px;
        width: 24rem; 
        border: none;
        border-radius: 0; 
        margin-top: 380px;
        margin-left: 85px;
        display:block;
        &:focus {
            outline: none;
          }
          &:focus ~ label,
          &:valid ~ label { 
            top: 8px; 
            font-size: 14px; 
            color: #9A7F3E;
          }
          &:focus ~ label.track,
          &:valid ~ label.track { 
            top: -20px;
            left: 0;
            font-size: 14px;
            color: #cecece;
          } 

        @media(max-width: 1200px){   
            width: 15rem; 
        }

        @media(max-width: 580px){   
          margin-left: 0px;
          font-size: 12px;
          padding: 20px 5px 10px;
          width: 150px;
        } 
      }

      div {
        div{
          @media(max-width: 580px){   
            left: 5px; 
          } 
        }
      }
}