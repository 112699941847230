//=================================================
// Services
//=================================================

.service-items {
  background-image: url('../../img/bg/network-bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100% !important;
  .service-grid {
    padding-top: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
    .service-card:nth-child(2),
    .service-card:nth-child(4) {
      margin-top: -8rem;
    }
    @media #{$media_lg} {
      .service-card:nth-child(2),
      .service-card:nth-child(4) {
        margin-top: 0;
      }
      grid-template-columns: 1fr 1fr;
      row-gap: 2rem;
    }
    @media #{$media_sm} {
      grid-template-columns: 1fr;
    }
  }
  .service-card {
    -webkit-animation: bouncer 2.5s infinite alternate;
    animation: bouncer 2.5s infinite alternate;
    position: relative;
    margin: 0 auto;
    display: block;
    width: 400px;
    height: 400px;
    border-radius: 100%;
    padding: 0.8rem;
    -webkit-box-shadow: 0px 0px 57px -16px rgba(102, 102, 102, 0.73);
    -moz-box-shadow: 0px 0px 57px -16px rgba(102, 102, 102, 0.73);
    box-shadow: 0px 0px 57px -16px rgba(102, 102, 102, 0.73);
    @media #{$media_xxl} {
      width: 300px;
      height: 300px;
    }
    @media #{$media_md} {
      width: 250px;
      height: 250px;
    }
    &__image {
      border-radius: 100%;
    }
    &__label {
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      width: 100%;
      background-color: white;
      text-align: center;
      padding: 0.8rem 0;
      h3 {
        font-size: 18px !important;
        color: $gold;
      }
    }
    &__action {
      cursor: pointer;
      position: absolute;
      vertical-align: center;
      right: -1rem;
      top: 40%;
      border-radius: 100%;
      padding: 1.3rem;
      background-color: $gold;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: #fff;
        font-size: 30px;
        @media #{$media_md} {
          font-size: 20px;
        }
      }
    }
    @-webkit-keyframes bouncer {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-30px);
      }
    }
    @keyframes bouncer {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-30px);
      }
    }
  }
  .service-item {
    position: relative;
    overflow: hidden;
    float: left;
    width: 33.33%;
    color: $body_text_tertiary_color;
    transition-property: none;
    transition-duration: 0s;

    @media #{$media_xl} {
      width: 50%;
    }

    @media #{$media_sm} {
      width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $body_text_tertiary_color;
    }

    .service-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      padding-top: 2.105rem;
      padding-bottom: 2.105rem;

      .service-content-sec {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding-left: 2.105rem;
        padding-right: 2.105rem;
        transform: translateY(0);
        transition: transform $transition-s ease-in-out;

        .button {
          opacity: 0;
          margin-top: $space_sm;
          transition: opacity $transition-s ease-in-out;
        }
      }
    }

    .img {
      &.object-fit {
        &:before {
          padding-top: 110%;
        }

        @media #{$media_xl} {
          &:before {
            padding-top: 80%;
          }
        }

        @media #{$media_sm} {
          &:before {
            padding-top: 85%;
          }
        }
      }

      &-bg-color {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-image: linear-gradient(
          to bottom,
          rgba(11, 5, 38, 0),
          $body_main_element_primary_color
        );
        opacity: 0.8;
        @extend .transition-all;
      }
    }

    .object-fit-cover {
      @extend .transition-all;
      transform: perspective(1px) scale(1) translateZ(0);
      backface-visibility: hidden;
    }

    &:hover {
      .service-content {
        .service-content-sec {
          transform: translateY(-2.105rem);

          .button {
            opacity: 1;
          }
        }
      }

      .img {
        &-bg-color {
          background-color: $body_main_element_primary_color;
          opacity: 0.86;
        }

        .object-fit-cover {
          transform: perspective(1px) scale(1.1) translateZ(0);
        }
      }
    }
  }

  &.type-1 {
    .service-item {
      &:nth-child(4n + 2),
      &:nth-child(4n + 4) {
        .img {
          &.object-fit {
            &:before {
              padding-top: 55%;
            }

            @media #{$media_md} {
              &:before {
                padding-top: 80%;
              }
            }
          }
        }
      }
    }
  }

  &.type-2 {
    .service-item {
      &:nth-child(5n + 2),
      &:nth-child(5n + 3),
      &:nth-child(5n + 4),
      &:nth-child(5n + 5) {
        .img {
          &.object-fit {
            &:before {
              padding-top: 55%;
            }

            @media #{$media_md} {
              &:before {
                padding-top: 80%;
              }
            }
          }
        }
      }
    }
  }

  &.type-3 {
    .service-item {
      .img {
        &.object-fit {
          &:before {
            padding-top: 55%;
          }

          @media #{$media_md} {
            &:before {
              padding-top: 80%;
            }
          }
        }
      }
    }
  }

  &.type-4 {
    .service-item {
      .img {
        &.object-fit {
          &:before {
            padding-top: 110%;
          }
        }
      }
    }
  }
}
