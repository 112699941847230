//=================================================
// Main
//=================================================

/*=============================
// All page
=============================*/
main {
    padding-top: $main_padding_t;
    padding-bottom: $main_padding_b;

    &.content-no-spacing {
        padding-top: 0;
        padding-bottom: 0;

        #page-content {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .bg-black{
        background-color: #383838;
    }
}

/*=============================
// Home page
=============================*/
.home {
    main {
        padding-bottom: 0;
    }

    .custom-shape-divider-bottom-1617209220 {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: 90;
    }

    .custom-shape-divider-bottom-1617209220 svg {
        position: relative;
        display: block;
        width: calc(266% + 1.3px);
        height: 159px;
    }

    .custom-shape-divider-bottom-1617209220 .shape-fill {
        fill: #FFFFFF;
    }

    .about-us {
        background-image: url('../../../assets/img/placeholder/white-cargo.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .mission-vision {
        &__bg-black{
            background-color: #383838;
        }
    }
    .partners{
        background-color: #383838;
    }

    
}
.other-services-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 2rem;
    column-gap: 1rem;
    grid-template-rows: min-content;
    @media #{$media_sm} {
        grid-template-columns: 1fr 1fr;
    }
}
/*=============================
// Home blog page
=============================*/
.home {
    &.blog {
        main {
            padding-bottom: $main_padding_b;
        }
    }
}


/*=============================
// Search results page
=============================*/
.search-results {
    main {
        padding-bottom: $main_padding_b - 2rem;
    }
}

/*=============================
// 404 page
=============================*/
.error404 {
    main {
        padding-bottom: $main_padding_b - 2rem;
    }
}

/*=============================
// privacy page
=============================*/
.privacy {
    margin: 50px 100px;
    p {
        padding: 1rem;
    }
}