.control-dots li {
  background-color: #9a7f3e !important;
  height: 12px !important;
  width: 12px !important;

  &:before {
    display: none;
  }
}

.slide {
  &:before {
    display: none;
  }
}

.carousel-status {
  display: none;
}

.thumbs-wrapper {
  display: none;
}
